import ApiService from '@/common/api/api.service';

let apiBasePath = 'Matricula';

const MatriculaService = {
  // CRUD:
  async salvar(form) {
    let result = [];
    if (!form.id) result = await ApiService.post(`${apiBasePath}`, form);
    else result = await ApiService.put(`${apiBasePath}`, form);
    return result;
  },

  async atualizar(form) {
    const { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },

  // ATIVAÇÃO/DESATIVAÇÃO:
  async ativar(id) {
    return await ApiService.patch(`${apiBasePath}/ativar-matricula?id=${id}`);
  },
  async inativar(id) {
    return await ApiService.patch(`${apiBasePath}/inativar-matricula?id=${id}`);
  },

  // VÍNCULOS:
  async criarVinculo(vinculo) {
    let result = await ApiService.post(`${apiBasePath}/criar-vinculo`, vinculo);
    return result;
  },
  async removerVinculo(matriculaId, pessoaId) {
    let result = await ApiService.delete(
      `${apiBasePath}/remover-vinculo?MatriculaId=${matriculaId}&PessoaId=${pessoaId}`
    );
    return result;
  },
  async atualizarVinculo(vinculo) {
    let result = await ApiService.put(`${apiBasePath}/atualizar-vinculo`, vinculo);
    return result;
  },

  async buscarVinculosPorMatriculaId(matriculaId) {
    let { data } = await ApiService.get(`${apiBasePath}/obter-vinculos?matriculaId=${matriculaId}`);
    return data;
  },
  // DESCONTOS:
  async adicionarDesconto(desconto) {
    let result = await ApiService.post(
      `${apiBasePath}/adicionar-desconto`,
      desconto
    );
    return result;
  },
  async removerDesconto({ matriculaId, id }) {
    return await ApiService.delete(
      `${apiBasePath}/remover-desconto?MatriculaId=${matriculaId}&Id=${id}`
    );
  },
  async getDescontos() {
    let { data } = await ApiService.get(`Desconto`);
    return data;
  },

  // BUSCAR:
  async listar(paginacao) {
    let { data } = await ApiService.get(`${apiBasePath}`, {
      params: paginacao,
    });
    return data;
  },
  async listarSemPaginacao() {
    let { data } = await ApiService.get(`${apiBasePath}/obter-todas`);
    return data;
  },
  async buscarPorId(id) {
    let { data } = await ApiService.get(`${apiBasePath}/${id}`);
    return data;
  },
  async buscarTodosPorNomeCodigo(nome) {
    let { data } = await ApiService.get(`${apiBasePath}/obter-com-paginacao`, {
      params: {
        Nome: nome,
        TamanhoDaPagina: 50
      },
    });
    data.data.itens = data.data.itens.map((row) => ({
      ...row,
      text: `${row.codigoMatricula} - ${row.nome}`,
      value: row.matriculaId,
    }));
    return data.data;
  },

  // FILTRO:
  async obterMatriculasPorFiltroPaginado(filtro, paginacao) {
    let { data } = await ApiService.get(
      `${apiBasePath}/obter-matricula-por-filtro-paginado`,
      { params: { ...filtro, ...paginacao } }
    );
    return data;
  },

  // RELATORIOS
  async gerarRelatorioIrmaosPorAnoLetivoId(anoLetivoId) {
    let { data } = await ApiService.get(
      `${apiBasePath}/relatorio/irmaos?anoLetivoId=${anoLetivoId}`
    );
    return data;
  },
};

export default MatriculaService;
