<template>
  <b-row>
    <b-col md="6">
      <filtro-matricula
        :items="items"
        :itemsFiltro="filtro.alunoDados"
        @filtrar="addFiltro"
        ref="filtroMatricula"
      />
    </b-col>
    <b-col md="3">
      <input-date-only-btn
        ref="periodoInicial"
        v-model="filtro.dataVencimento"
        :label="$t('FINANCEIRO.DATA_VENCIMENTO')"
        @change="getFiltro"
        required
      />
    </b-col>
    <b-col md="3">
      <input-date-only-btn
        ref="periodoFinal"
        v-model="filtro.dataVencimentoLimite"
        :label="$t('FINANCEIRO.DATA_VENCIMENTO_FINAL')"
        @change="getFiltro"
        required
      />
    </b-col>
    <b-col md="auto">
      <b-button
        variant="secondary"
        class="mb-3 mb-md-3 w-100"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button
        variant="info"
        class="mb-3 mb-md-3 w-100"
        @click="ImprimirCarta"
        :disabled="!camposPreenchidos"
      >
        {{ $t('FINANCEIRO.IMPRIMIR_CARTA_COBRANCA') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button
        variant="info"
        class="mb-3 mb-md-3 w-100"
        @click="verInadimplencia"
        :disabled="!camposPreenchidos"
      >
        {{ $t('FINANCEIRO.VER_INADIMPLENTES') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Components:
import FiltroMatricula from './FiltroMatricula';
import { InputDateOnlyBtn } from '@/components/inputs';

export default {
  props: {
    camposPreenchidos: { type: Boolean, default: false },
  },
  components: {
    // Filtro,
    FiltroMatricula,
    InputDateOnlyBtn,
  },
  data() {
    return {
      matriculaId: false,
      filtro: {},
      resultadoFiltro: {},
      items: [],
    };
  },

  methods: {
    // FUNÇÕES FILTRO:
    getFiltro() {
      this.$emit('filtrar', this.filtro);

      if (
        this.filtro.dataVencimento == '' ||
        this.filtro.dataVencimentoLimite == ''
      ) {
        this.$emit('habilitar-botoes');
      }
    },
    addFiltro(matriculaId) {
      this.filtro.matriculaId = matriculaId;

      if (this.filtro.matriculaId) {
        this.matriculaId = true;
      }

      if (
        this.filtro.dataVencimento !== '' &&
        this.filtro.dataVencimentoLimite !== ''
      ) {
        this.getFiltro();
      }
    },
    filtrar(item) {
      this.$emit('filtrar', item);
    },
    limparFiltro() {
      this.filtro = {};
      this.$refs.filtroMatricula.limparFiltroMatricula();
      this.matriculaId = false;
    },
    // relatórios
    ImprimirCarta() {
      this.$emit('imprimir-carta');
    },
    verInadimplencia() {
      this.$emit('ver-inadimplencia');
    },
  },
};
</script>
