<template>
  <div class="row">
    <b-col>
      <input-select-search
        label="Clique abaixo para selecionar um aluno ou sua matrícula:"
        v-model="matriculaSelecionada"
        :options="opcoes.matricula"
        placeholder="Digite ou procure"
      />
    </b-col>
  </div>
</template>

<script>
// Utils:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
//Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service';
// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    InputSelectSearch,
  },
  props: {
    itemsFiltro: { type: [Array] },
    items: { type: [Array] },
  },
  watch: {
    matriculaSelecionada: ['filtrar'],
  },
  data() {
    return {
      opcoes: {
        matricula: [],
      },
      matriculaSelecionada: null,
    };
  },
  mounted() {
    this.listarMatriculasSemPaginacao();
  },
  methods: {
    limparFiltroMatricula() {
      this.matriculaSelecionada = '';
    },
    filtrar() {
      this.$emit('filtrar', this.matriculaSelecionada);
    },
    listarMatriculasSemPaginacao() {
      this.$store.dispatch(START_LOADING);
      MatriculaService.listarSemPaginacao()
        .then(({ data }) => {
          if (data.length) {
            data = data.map((row) => {
              return {
                ...row,
                text: `${row.codigoMatricula} - ${row.nome}`,
                value: row.id,
              };
            });

            this.opcoes.matricula = data;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
