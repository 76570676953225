<template>
  <div>
    <titulo-cobranca />
    <filtro-cobranca
      :camposPreenchidos="camposPreenchidos"
      @filtrar="filtrar"
      @imprimir-carta="ImprimirCarta"
      @ver-inadimplencia="verInadimplencia"
      @habilitar-botoes="habilitarBotoes"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import mensagem from '@/common/utils/mensagem';
//Service
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service';
// Components:
import TituloCobranca from './components/Titulo';
import FiltroCobranca from './components/Filtro';

export default {
  components: {
    TituloCobranca,
    FiltroCobranca,
  },
  data() {
    return {
      camposPreenchidos: false,
      filtro: {
        matriculaId: null,
        dataVencimento: null,
        dataVencimentoLimite: null,
      },
    };
  },
  methods: {
    filtrar(item) {
      this.filtro.matriculaId = item.matriculaId;
      this.filtro.dataVencimento = item.dataVencimento;
      this.filtro.dataVencimentoLimite = item.dataVencimentoLimite;
      if (this.filtro.dataVencimento && this.filtro.dataVencimentoLimite) {
        this.camposPreenchidos = true;
      }
    },
    habilitarBotoes() {
      this.camposPreenchidos = false;
    },
    ImprimirCarta() {
      this.$store.dispatch(START_LOADING);
      ContaReceberService.imprimirCartaCobranca(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Carta_Cobranca_' + data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    verInadimplencia() {
      this.$store.dispatch(START_LOADING);
      ContaReceberService.imprimirInadimplencia(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Extato_Inadimplencia_' + data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
