import ApiService from '@/common/api/api.service';

const urlBase = 'Titulo/contas-receber';

const ContaReceberService = {
  async criar(form) {
    let result = await ApiService.post(`${urlBase}/criar`, form);
    return result;
  },
  async buscarMatriculas() {
    let { data } = await ApiService.get(
      `Matricula?TamanhoDaPagina=10&NumeroDaPagina=1`
    );
    return data.data;
  },
  async buscarContrato() {
    let { data } = await ApiService.get('Contrato/obter-todos');
    return data.data;
  },
  async buscarContratoDadosBasicos() {
    let { data } = await ApiService.get('Contrato/obter-todos-basico');
    return data.data;
  },
  async buscarConta(paginacao) {
    let { data } = await ApiService.get(`Conta`, { params: paginacao });
    return data.data;
  },
  async buscarDesconto(paginacao) {
    let { data } = await ApiService.get(`Desconto`, { params: paginacao });
    return data.data;
  },
  async buscarContasReceber(paginacao, filtro) {
    let { data } = await ApiService.get(`${urlBase}/obter-por-filtro`, {
      params: { ...paginacao, ...filtro },
    });
    data.data.itens = data.data.itens.map((row) => ({
      ...row,
      text: row.nome,
      value: row.id,
      _rowVariant: this.corLinhaContasReceber(row),
    }));

    return data;
  },
  corLinhaContasReceber(row) {
    if (row.statusPagamentoDescricao == 'Liquidado') return 'success';
    if (row.statusPagamentoDescricao == 'Cancelado') return 'danger';
  },
  async buscarStatusPagamento() {
    let { data } = await ApiService.get(`enumeradores/status-pagamento`);
    return data.data;
  },
  async buscarHistoricoContaReceber() {
    let { data } = await ApiService.get(`HistoricoLancamento/contas-a-receber`);
    return data.data;
  },
  async baixarTitulo(form, ehLista) {
    const url = ehLista
      ? 'Titulo/contas-receber/baixar-lista'
      : 'Titulo/contas-receber/baixar';
    let result = await ApiService.patch(url, form);
    return result;
  },
  async estornar(id) {
    let { data } = await ApiService.patch(`Titulo/contas-receber/estornar`, {
      id,
    });
    return data.data;
  },
  async calcularJuros(id) {
    const resultadoIdsFormatadosParametro = id
      .map(({ id, dataPagamento }) => {
        return `DataPagamento=${dataPagamento}&ids=${id}&`;
      })
      .join('');

    let { data } = await ApiService.get(
      `Titulo/contas-receber/calcular-juros?${resultadoIdsFormatadosParametro}`
    );
    return data.data;
  },
  async buscarListaDeContasReceber(id) {
    const resultadoIdsFormatadosParametro = id
      .map((id) => {
        return `ids=${id}&`;
      })
      .join('');

    let { data } = await ApiService.get(
      `Titulo/contas-receber/busca-por-ids?${resultadoIdsFormatadosParametro}`
    );
    return data.data;
  },
  async atualizarDesconto(form) {
    let { data } = await ApiService.patch(
      `Titulo/contas-receber/atualizar-desconto`,
      form
    );
    return data.data;
  },
  async removerDesconto(form) {
    let { data } = await ApiService.patch(
      'Titulo/contas-receber/remover-desconto',
      form
    );
    return data;
  },
  async liberarPortal(form) {
    let { data } = await ApiService.patch(
      `Titulo/contas-receber/liberar-portal`,
      form
    );
    return data;
  },
  async bloquearPortal(form) {
    let { data } = await ApiService.patch(
      `Titulo/contas-receber/bloquear-portal`,
      form
    );
    return data;
  },
  async cancelarTitulo(form) {
    let { data } = await ApiService.patch(
      `Titulo/contas-receber/cancelar-titulo`,
      form
    );
    return data;
  },
  // RELATORIOS
  async imprimirCartaCobranca(filtro) {
    let { data } = await ApiService.get(`${urlBase}/imprimir-carta-cobranca`, {
      params: filtro,
    });
    return data;
  },
  async imprimirInadimplencia(filtro) {
    let { data } = await ApiService.get(`${urlBase}/imprimir-inadimplencia`, {
      params: filtro,
    });
    return data;
  },
  async imprimirRecibo(form) {
    let { data } = await ApiService.post(
      `LancamentoCaixa/imprimir-recibo`,
      form
    );
    return data;
  },
  async obterPorFiltroLista(filtro) {
    let { data } = await ApiService.post(
      `${urlBase}/obter-por-filtro-lista`,
      null,
      { params: filtro }
    );
    return data;
  },
  async gerarRelatorioAnaliseRetroativa(filtro) {
    let { data } = await ApiService.get(
      `${urlBase}/imprimir-analise-retroativa`,
      { params: filtro }
    );
    return data;
  },
  async gerarExtrato(filtro) {
    let { data } = await ApiService.get(`${urlBase}/imprimir-extrato`, {
      params: filtro,
    });
    return data;
  },
};

export default ContaReceberService;
